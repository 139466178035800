div.page {
  min-height: 100vh;
  height: 100vh;
  background-image: url("/assets/images/BG-Outlined.svg");
  background-size: cover;
}

div.card {
  min-height: 376px;
  max-width: 394px;
  width: 394px;
}
