.input {
  min-height: 4rem;
}

div.popover:global(.oui-popover) {
  max-width: 394px;
  & > :global(.oui-popover-inner) {
    padding-left: 0.25rem;
    padding-top: 0.5rem;
  }
}
