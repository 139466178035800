.back-btn {
  display: inline-flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0 0.5rem;
  cursor: pointer;

  a {
    padding: 0.25rem;
  }

  &:hover {
    background-color: #e9ecef;
  }
}
